<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader";
import appConfig from "../../../app.config";

import axios from 'axios';
import PodcastItem from "./PodcastItem.vue";

export default {
  page: {
    title: "Podcasts",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      data: {
        list: [],
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    PodcastItem
  },
  created: function () {
    console.log('Tag', this.$route.params.value);
    axios.get('/api/podcasts/tag/' + this.$route.params.value)
      .then((response) => {
        this.data = response.data;
      })
      .catch(function () {
      });
  },
  computed: {
    getTagFromUrl() {
      return this.$route.params.value;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('t-podcasts-bytag-title')" :description="$t('t-podcasts-bytag-description')">
      <button type="button" class="btn btn-primary" v-if="false">
        <i class="ri-add-line align-middle me-1"></i> Create Podcast
      </button>
    </PageHeader>

    <div class="row">
      <div class="col-lg-12">
        <div class="team-list grid-view-filter row">
          <div class="col" v-for="p in data.list" v-bind:key="p">
            <PodcastItem v-bind:data="p"></PodcastItem>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>